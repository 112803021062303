$lightGray: #F7F7F7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;
$invalRed: #DC3545;

.form {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
}

.formLabel {
    font-weight: 600;
}

.formLabelInfo {
    font-weight: 600;
    display: flex;
    align-items: center;
}

.formGroup {
    margin: 0 20px 0 10px;
}

.filterRow {
    margin: 0px 20px;
    display: flex;
    gap: 20px;
}

.filterRow .formInput {
    width: 260px;
}

.formLabel {
    margin: 10px 0 ;
}

.formBtn {
    width: 260px;
    align-self: flex-end;
}

.formGroupDate {
    margin-right: 20px;
}

.formTitle {
    margin-left: 10px;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px 20px;
    max-height: 60vh;
    overflow: auto;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
}

.tableTitle {
    padding: 50px 0px 0px 20px;
}

.footer {
    display: flex;
    position: fixed;
    height: 7%;
    background-color: #EFEFEF;
    bottom: 5px;
    left: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.monitorInfo {
    display: flex;
    justify-content: space-around;
}

.infoBlock {
    display: flex;
    flex-direction: column;
}

.infoBlockColumm {
    display: flex;
    margin: 10px;
    flex-direction: column;
}

.fontBlue {
    color: $blue;
}

.information {
    font-style: italic;
    margin: 30px 20px;
    font-size: large;
}

.modalForm {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.modalFormGroup {
    margin-top: 20px;
}

.modalFooter {
    background-color: #F7F7F7;
    justify-content: center;
}

.spanOpcional {
    font-size:13px;
    color: $gray;
    margin-left: 5px;
}

.showTtp {
    color: red;
}

.showFeedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: $invalRed;
}

.hidden {
    display: none;
}