.image_preview_container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 6px;
}

.image_preview {
  position: relative;
  width: 10%;
  cursor: pointer;
}

.image_preview img {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.image_preview .remove_btn {
  display: block;
  position: absolute;
  top: -6px;
  right: -6px;
  cursor: pointer;
}

.image_preview:hover .remove_btn {
  display: block;
}
