$lightGray: #F7F7F7;
$red: #E90101;
$blue: #0D6EFD;

.list{
  height: 60vh;
  max-height: 60vh;
  overflow-y: scroll;
}

// ::-webkit-scrollbar {
//   width: 8px;
// }

/* Track */
// ::-webkit-scrollbar-track {
//   border-radius: 0px;
// }

// ::-webkit-scrollbar-thumb {
//   background: grey;
//   border-radius: 8px;
// }

.splitter{
  width: 1px;
}

.text-local{
  font-weight: 600 !important;
}

.form1{
  display: flex;
  flex-direction: column !important;
  flex-flow: column;
}

.checkbox_input{
  width: 250px;
}

.tablesColumm {
  display: flex;
  flex-direction: column;
}

.alignRowSpaceBtw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 10px 10px 10px;
}

.tableGrayMargin {
  background-color: $lightGray;
  margin: 20px 10px;
}

.tableGrayNoMargin {
  background-color: $lightGray;
  margin: 20px 0px;
}

.blockTitle {
  font-weight: bold;
}

.tableLines {
  white-space: nowrap;
}


.fontRedBold {
  color: $red;
  font-weight: bold;
}

.fontBlueBold {
  color: $blue;
  font-weight: bold;
}
.fontBold {
  font-weight: bold;
}

.form {
  display: flex;
  align-items: flex-end;
  margin-left: 10px;
}

.formGroup {
  margin: 0 10px 10px 0;
}

.articleMargin {
  margin: 10px;
}

.articleGrayPageOne {
  background-color: $lightGray;
  margin: 20px 20px;
  overflow-y: scroll;
  max-height: 60vh;
  min-height: 60vh;
  height: 60vh;
  
}

.tableScrool {
  overflow:scroll !important;
  height: 100%;
}

.FormMarginNumberPage {
  margin: 10px 20px;
}

.formPageSelect {
  display:flex; 
  justify-content: flex-end;
  margin: 10px 20px;
}

.formRevisaoAndamento {
  display:flex;
  align-items: flex-end;
}

.formGroupRevisaoAndamento {
  display:flex;
  flex-direction: column;
  margin: 10px 10px;
  width: 100%;
  white-space: nowrap;
}

.formMarginRevisaoAndamento {
  display:flex;
  margin: 10px 20px 0 0;
  width: 100%;
  white-space: nowrap;
  max-width: fit-content;
}

.formBtnRevisaoAndamento {
  white-space: nowrap;
  margin: 10px;
}

.divFormRevisaoAndamento {
  padding: 20px;
  border: #dee2e6 solid 1px;
  border-radius: 4px;
  margin: 10px 10px;
}
.footerRevisaoAndamento {
  background: $lightGray;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center
}

.btnProsseguirMargin {
  margin: 10px;
}
  
.titleResultRevisao {
  margin: 30px 20px;
}

.articleValidaRevisao {
  padding: 20px;
  border: #dee2e6 solid 1px;
  border-radius: 4px;
  margin: 50px 5px;
}

.articleMarginTwenty {
  margin: 20px;
}

.blockTitle {
  font-weight: bold;
}
