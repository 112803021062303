$lightGray: #F7F7F7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;
$green: #01A34C;
$invalRed: #DC3545;

.articleMargim {
    margin-top: 30px;
}

.form {
    display: flex;
    align-items: flex-end;
}

.formGroup {
    margin-right: 20px;
}

.formSelectModal,
.formControlModal {
    width: 60vh;
    // margin-bottom: 20px;
}

.formLabel {
    font-weight: bold;
}

.articleGray {
    background-color: $lightGray;
    margin-top: 30px;
    max-height: 550px;
    height: 550px;
    overflow: auto;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
}

.monitorInfo {
    display: flex;
}

.infoBlockDisplay{
    display: flex;
    justify-content: space-around;
}

.infoBlock {
    display: flex;
    flex-direction: column;
}

.infoBlockColumm {
    display: flex;
    margin: 10px;
    flex-direction: column;
}

.discountAmount {
    color: $blue;
    font-size: 18px;
    font-weight: bold;
}

.modalFooter {
    background-color: $lightGray;
    justify-content: center;
}

.fontBlue {
    color: $blue;
}

.fontRed {
    color: $red;
    font-weight: bold;
}

.spanOpcional {
    font-weight: normal;
    font-size:13px;
    color: $gray;
    margin-left: 5px;
}

.accordionFlex {
    display: flex;
}

.widthModal {
    width: 80vw !important;    /* Occupy the 90% of the screen width */
    max-width: 80vw !important;
}

.marginTop{
    margin-top: 30px;
}

.hidden {
    display: none;
}

.hover {
    cursor: pointer;
}

.showFeedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: $invalRed;
}