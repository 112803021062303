.flex_container_col {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flex_container_row {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
}

.div_30w {
  width: 30%;
}

.div_70w {
  width: 70%;
}

.pecas_wrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  border: 1px solid #00000030;
  padding: 6px;
  min-width: 41.5px;
  justify-content: center;
}

.peca_item {
  padding: 4px 8px;
  background-color: #00000010;
  border-radius: 4px;
}

.no_info {
  font-weight: 500;
}

.btn {
  width: 160px;
}

.flex_container_btn_row {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.modal_footer {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btn_modal {
  width: 40%;
}

.label {
  font-weight: 500;
}