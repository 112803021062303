$lightGray: #F7F7F7;

.form {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
}

.formGroup {
    margin: 0 10px 0 0;
}

.formNaoRetornoAmostra {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.formGroupNaoRetornoAmostra {
    margin: 10px 0;
}

.articleRow {
    display: flex;
}

.formEnvioAmostra {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.formGroupEnvioAmostra {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    min-width: 100%;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px 10px;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
}