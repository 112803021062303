$blue: #0D6EFD;
$lightGray: #F7F7F7;
$gray: #777777;
$invalRed: #DC3545;

.form {
    display: flex;
    align-items: flex-end;
    margin: 20px;
    gap: 30px;
}

.productInfo {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
}

.productInfo > div {
    width: 18%;
}

.fontBlue {
    color: $blue;
    min-height: 38px;
}

.dataToFill {
    background-color: $lightGray;
    margin: 20px;
    padding: 5px;
}

.title {
    margin: 40px 20px 20px;
}

.formWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.formRow {
    background-color: $lightGray;
    padding: 20px;
    gap: 20px;
    display: flex;
    width: 100%;
}

.formCell {
    width: 20%;
}

.articleBtn {
    width: 100%;
    padding: 30px 30px 60px;
    text-align: center;
}

.btn {
    width: 18%;
}

.spanOpcional {
    font-size: 13px;
    color: $gray;
    margin-left: 5px;
}

// ----- //

.filterWidth {
    width: 40%;
}

.opaque {
    opacity: 0.5;
}

.hoverBtn:hover {
    cursor: auto;
}

.btnLimit {
    margin: 0 auto;;
    width: 400px;
}

.flex {
    display: flex;
}

.hidden {
    display: none;
}

// ----- //

.showFeedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: $invalRed;
}

.headerWrapper {
    display: flex;
    gap: 20px;
}

.headerWrapper > div {
    // width: calc(50% - 10px);
    width: 50%;
}

.formFilters {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
    row-gap: 20px;
    justify-content: space-between;
}

.formFilters .filterWidth {
    width: 32%;
}

.formBtn {
    align-self: flex-end;
}

.formLabel {
    margin-bottom: 0.5rem;
}
