$lightGray: #F7F7F7;
$gray : #dee2e6;
$blue: #0D6EFD;
$red: #DC3545;

.articleMargin,
.modalCollapseMargin {
    margin-bottom: 10px;
}

.articleBorder {
    border: 1px solid $gray;
    border-radius: 4px;
    //box-shadow: 0px 4px 4px 0px #00000040;
    padding: 10px;
    margin-top: 20px;
}

.collapseTitle {
    background-color:#C4C4C4;
    margin: 0 auto;
    font-weight: 500;
    display: flex;
    align-items: center;
}


.gridRow {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 350px));
    grid-gap: 15px 15px;
    justify-content: center;
    align-items: baseline;
    background-color: #d3d3d340;
    padding: 10px;
}

.gridItem {
    display: flex;
    flex-direction: column;
}

.gridRow .gridItem span {
    line-height: 1;
}

.fontBlue {
    color: $blue;
    margin-top: 5px;
}

.fontRed {
    color: $red;
    margin-top: 5px;
}

.collapseDiv {
    display: flex;
    justify-content: space-around;
    font-style: italic;
    align-items: flex-start;
}

.collapseDivDiv {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.spanRed {
    color: red;
    font-weight: bold;
}

.spanGray {
    color: #777777;
}

.formLabel {
    font-weight:600;
}

//Tablet
@media screen and (min-width: 0px) and (max-width: 1023px) {

    .formLine {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .formIdentificacao {
        width: 100%;
        margin-bottom: 10px;
    }

    .formDadosanalise {
        width: 100%;
        margin-bottom: 10px;
    }
}

//Desktop
@media screen and (min-width: 1024px) {

    .formLine {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .formIdentificacao {
        width: 16%;
    }

    .formDadosanalise {
        width: 20%;
    }
}

.formColumm {
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
}

.buttonRight {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.form-Identificacao {
    margin: 10px 0;
    width: 15%;
}

.footer {
    display: flex;
    position: fixed;
    height: 50px;
    background-color: #EFEFEF;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.footerBtn {
    margin: 0px 10px;
}

.modalBody {
    display: block;
}

.secondQaInfo {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
}

.formSecondQaInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.spanBold {
    font-weight: bold;
}

.backgroundMiddleGray {
    background-color: #EDEDED;
    padding: 5px;
}

.modalFormLine {
    display: flex;
}

.modalFormLineLine {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left:20px;
}

.formNameLine {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 50%;
    margin: 10px 0px;
}

.marginRight {
    margin-right: 10px;
}

.table {
    overflow: hidden;
}

.borderThin {
    border: 1px solid #7777;
}

.sectionBorder {
    border: 1px solid #7777;
    border-radius: 4px;
    margin: 20px 0px;
}

.sectionContent {
    //width: auto;
    margin: 20px;
}

.modalFooter {
    background-color: #EFEFEF;
    width: 100%;
    justify-content: center;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.spanOpcional {
    color: #777777;
    font-size: 14px;
    padding-left: 5px;
}


.tableLines {
    white-space: nowrap;
    cursor:pointer;
}

.articleGrayFlex {
    background-color: $lightGray;
    margin: 20px 10px;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 4px;
}

.tableStyle {
    min-width: 20vh;
    max-height: 30vh;
    background-color: white;
    padding: 10px;
    border: 1px solid $gray;
    overflow: scroll;
}

.formGroup {
    min-width: 35vh;
    margin-bottom: 25px;
}

.formLabel {
    font-weight: 600;
}

.modalFooterWithoutShadow {
    background-color: #EFEFEF;
    width: 100%;
    justify-content: center;
}

.radioBtnListGrade {
    background-color: white;
    border-radius: 4px;
    padding: 20px;

}

.radioBtnListMedidas {
    background-color: white;
    border-radius: 4px;
    margin: 10px;
    margin-left: 20px;
    padding: 10px;

}

.formModal {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.articleFlexRow {
    display: flex;
}