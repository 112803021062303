$lightGray: #F7F7F7;
$blue: #0D6EFD;
$invalRed: #DC3545;

.form {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
    margin-top: 20px;
}

.formGroup {
    margin: 0 10px 10px 0;
    width: 14vw;
}

.formLabel {
    font-weight: 600;
}

.formBtn {
    margin: 0 10px 10px 0;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px 10px;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
}

.fontBlue {
    color: $blue;
}

.monitorInfo {
    display: flex;
    justify-content: space-around;
    font-size: large;
}

.infoBlock {
    display: flex;
    flex-direction: column;
}

.infoBlockColumm {
    display: flex;
    margin: 10px;
    flex-direction: column;
}

.modalFooter {
    background-color: #F7F7F7;
    justify-content: center !important;
}

.iconCursor {
    cursor: pointer;
}

.modalWidth {
    min-width: 80vw;
}

.formGroupModal {
    width: 30%;
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
}

.fontBlueModal{
    color: $blue;
    font-weight: 500;
}

.hidden {
    display: none;
}

.showFeedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: $invalRed;
}