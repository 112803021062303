
.header_section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 20px 0;
}

.flex_title {
  display: flex;
  margin: 10px 20px 0px;
}

.title {
  color: #000000AE;
}

// ---- //

.filter_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-bottom: 16px;
}

.filter_row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.form_input {
  width: 20%;
  max-width: 200px;
}

.filter_label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #000000AE;
}

.form_btn {
  align-self: flex-end;
  font-weight: 500;
  min-height: 32px;
  width: 20%;
  max-width: 200px;
  min-height: 32px;
}
