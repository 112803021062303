$lightGray: #f7f7f7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;
$fieldYellow: #fde8a3;
$divisionYellow: #ac9b66;

.headerWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
}

.formWrapper,
.actionWrapper {
    display: flex;
    gap: 20px;
}

.form {
    display: flex;
    align-items: flex-end;
    gap: 20px;
}

.formLabel {
    font-weight: 600;
    margin: 10px 0 ;
}

.formGroup {
    width: 200px;
}

.tablesRow {
    display: flex;
    margin-left: 20px;
}

.widthModal {
    min-width: 980px;
}

.incTableInsertWrapper {
    overflow-y: scroll;
    max-height: 240px;
    position: relative;
    display: flex;
    align-items: flex-end;
    background-color: $lightGray;
    justify-content: space-between;
    padding: 10px;
    margin-top: 30px;
}

.incTableWrapper {
    overflow-y: scroll;
    max-height: 240px;
    position: relative;
    margin-top: 30px;
}

.articleGrayAlt {
    background-color: $lightGray;
    width: auto;
    max-height: 850px;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    overflow: scroll !important;
    box-sizing: border-box;
}

.blockTitle {
    font-weight: bold;
    white-space: nowrap;
}

.tableLines {
    white-space: nowrap;
    vertical-align: middle;
}

.fontBlue {
    color: $blue;
}

.modalBtn {
    width: 100px;
}

.modalFooter {
    display: flex;
    justify-content: space-around;
}

.infoBlockDisplay{
    display: flex;
    justify-content: space-around;
}

.infoBlock {
    display: flex;
    flex-direction: column;
}

.infoBlockColumm {
    display: flex;
    margin: 10px;
    flex-direction: column;
}

.modalFooter {
    background-color: $lightGray;
    justify-content: center;
}

