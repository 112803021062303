.input-text {
  padding-left: 5px;
  vertical-align: middle;
  height: 2.3rem;
}

.btn-send {
  margin-left: 15px;
  box-shadow: none;
}

.table-wrapper {
  height: 400px;
  overflow: scroll;
}

.flex-bt {
  justify-content: space-between;
}

.head-50 {
  height: 60px;
}

.checkbox {
  height: 24px;
  width: 24px;
}

.table {
  width: 3100px;
}
