.modal_controle_body {
	display: flex;
	flex-direction: column;
	gap: 24px !important;
}

.warning_text {
	font-weight: 500;
	opacity: 0.9;
	text-align: justify;
}

.box {
  width: 16px !important;
  height: 16px !important;
}