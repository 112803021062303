.link {
  text-decoration: none;
  font-size: 16px;
  padding: 10px;
  color: black;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: left;
  align-self: center;
  margin: 0 auto;
}

.link:hover {
  background-color: lightgray;
  color: black;
}
