$lightGray: #F7F7F7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;

.form {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
}

.formGroup {
    margin: 0 10px 0 0;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px 10px;
}

.articleWhite{
    margin: 20px 10px;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
}

.colNoMargin {
    width: 100%;
}

.select {
    min-width: 200px;
}