$blue: #0D6EFD;
$red: #DC3545;

.articleMargin,
.modalCollapseMargin {
    margin-bottom: 10px;
}

.articleBorder {
    border: 1px solid #777777;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 10px;
    margin-top: 20px;
}

.collapseTitle {
    background-color:#C4C4C4;
    margin: 0 auto;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.gridRow {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 220px));
    grid-gap: 15px 15px;
    justify-content: start;
    align-items: baseline;
    background-color: #d3d3d340;
    padding: 10px;
}

.gridItem {
    display: flex;
    flex-direction: column;
}

.gridRow .gridItem span {
    line-height: 1;
}

.fontBlue {
    color: $blue;
    margin-top: 5px;
}

.fontRed {
    color: $red;
    margin-top: 5px;
}

@media only screen and (max-width:768px) {
    .gridItem {
        font-size: 14px;
    }
    
}

.spanRed {
    color: $red;
    font-weight: bold;
}

.spanGray {
    color: #777777;
}

.formLine {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.formColumm {
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
}

.buttonRight {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.form-Identificacao {
    margin: 10px 0;
    width: 15%;
}

.formDadosanalise {
    margin: 10px 0;
    width: 20%;
}

.footer {
    display: flex;
    position: fixed;
    height: 50px;
    background-color: #EFEFEF;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.modalBody {
    display: block;
}

.secondQaInfo {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
}

.formSecondQaInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.spanBold {
    font-weight: bold;
}

.backgroundMiddleGray {
    background-color: #EDEDED;
    padding: 5px;
}

.modalFormLine {
    display: flex;
}

.modalFormLineLine {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left:20px;
}

.formNameLine {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 50%;
    margin: 10px 0px;
}

.marginRight {
    margin-right: 10px;
}

.table {
    overflow: hidden;
}

.borderThin {
    border: 1px solid #7777;
}

.sectionBorder {
    border: 1px solid #7777;
    border-radius: 4px;
    margin: 20px 0px;
}

.sectionContent {
    //width: auto;
    margin: 20px;
}

.modalFooter {
    background-color: #EFEFEF;
    width: 100%;
    justify-content: center;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.spanOpcional {
    color: #777777;
    font-size: 14px;
    padding-left: 5px;
}


.tableLines {
    white-space: nowrap;
    cursor:pointer;
}