* {
  font-size: 13px;
}

.modal_body,
.defeitos_form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.flex_section {
  display: flex;
  gap: 16px;
}

.select_group {
  font-size: 13px;
}

.form_label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #000000AE;
}

.filter_label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #000000AE;
}

.filter_btn {
  font-size: 13px;
  font-weight: 500;
  width: 100px;
  align-self: flex-end;
  justify-self: flex-end;
  width: auto;
}

.warn_text {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 8px;
  margin: auto;
}