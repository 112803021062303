$lightGray: #F7F7F7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;
$green: #01A34C;
$invalRed: #DC3545;

.articleMargim {
    margin: 30px 10px;
}

.form {
    display: flex;
    align-items: flex-end;
}

.formGroup {
    margin-right: 20px;
}

.formSelectModal,
.formControlModal {
    width: 60vh;
    // margin-bottom: 20px;
}

.formLabel {
    font-weight: bold;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px 10px;
}

.modalBody {
    text-align: center;
}

.modalFooter {
    justify-content: center
}