$lightGray: #F7F7F7;

.articleForm {
    display: flex;
}

.form {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.formBtn {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: flex-end;
}

.formGroup {
    margin: 0 10px;
    padding-top: 10;
}

.formGroupDown {
    margin: 0 10px;
    padding-top: 20px;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px 10px;
}


.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
    cursor:pointer;
}