.image_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  justify-items: center;
  align-items: center;
}

.backdrop_z_override {
  z-index: 1055;
}

.image_wrapper {
  position: relative;
  width: 150px;
}

.image_wrapper img {
  border-radius: 6px;
  width: 100%;
  height: auto;
}

.image_wrapper .remove_btn {
  display: block;
  position: absolute;
  top: -12px;
  right: -12px;
  cursor: pointer;
}

.footer_wrapper {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.footer_btn {
  border: 1px solid #dadada !important;
  font-weight: 500;
  padding: 10px;
  width: 40%;
}