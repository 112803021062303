$blue: #0D6EFD;
$lightGray: #F7F7F7;
$red: #DC3545;
$black: #000000;

.formWrapper {
  display: flex;
  align-items: flex-end;
  margin-left: 20px;
  gap: 20px;
}

.formgroup {
  width: 180px;
  margin-top: 15px;
}

.stylesInput {
  height: 38px;
  margin-top: 4px;
}

.attachBtn {
  width: 250px;
  border: 1px solid $black;
  margin-left: 10px;
  cursor: pointer;
}

.searchBtn {
  width: 120px;
  margin-left: -10px;
  border: 1px solid $black;
  height: 38px;
}

.articleGray {
  background-color: $lightGray;
  margin: 20px 10px;
}

.blockTitle {
  font-size: 15px;
  font-weight: bold;
  ;
}

.modal_body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.product_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20;
  border: 1px solid #e3e1e1;
  border-radius: 4px;
  padding: 16px;
}

.product_info {
  display: flex;
  flex-direction: column;
}

.pi_label {
  font-size: 12px;
}

.pi_value {
  margin-left: 2px;
  font-weight: 500;
  color: #3d8afe;
}

.separator {
  background-color: #dee2e6;
  height: 1px;
  width: 100%;
}

.vertical_separator {
  background-color: #dee2e6;
  height: 22px;
  width: 1px;
}

.vertical_separator_max {
  background-color: #dee2e6;
  width: 1px;
}

.file_wrapper {
  display: flex;
  gap: 20px;
}

.custom_input_wrapper {
  display: flex;
  align-items: center;
  width: 30%;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  height: 38px;
  gap: 6px;
}

.input_filename {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.input_btn_delete,
.input_btn {
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  justify-self: right;
}

.input_btn_delete {
  padding: 0px 6px;
  border: 1px solid #dee2e6;
}

input[type=checkbox] {
  cursor: pointer;
}

.file_wrapper_outer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
}

.file_wrapper_inner {
  display: flex;
  gap: 4px;
  align-items: center;
  border: 1px solid #e3e1e1;
  border-radius: 4px;
  padding: 4px 6px;
  cursor: pointer;
}

.file_span {
  display: flex;
  align-items: center;
}

.file_column_wrapper {
  display: flex;
  justify-content: center;
  gap: 1%;
}

.file_comp_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.custom_input_brd:focus {
  border-width: 2px !important;
  border-color: #dee2e6 !important;
  box-shadow: none !important;
}

.delete_btn_wrapper {
  z-index: 99;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.backdrop_z_override {
  z-index: 1055;
}