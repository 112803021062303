$blue: #0D6EFD;
$lightGray: #F7F7F7;
$red: #DC3545;

.articleGray {
	background-color: $lightGray;
	margin: 20px 10px;
}

.articleTitle{
	display: flex;
	justify-content: space-between;
}

.modalBtn{
	width: 100px;
	height: 33px;
	margin: 14px 33px;
}

.modalFooter {
    background-color: #EFEFEF;
    width: 100%;
    justify-content: center;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.form_label {
	font-size: 13px;
	font-weight: 500;
	margin: 4px 20px 4px 4px;
	color: #000000AE;
}

.formWrapperModal {
	display: flex;
	align-items: flex-end;
}

.filterInputModal {
	width: 60%;
	padding: 10px 0;
	margin: 10px auto;
}

.filterText {
	display: flex;
	justify-content: flex-start;
	padding: 20px;
}


.textControl {
	display: flex;
	width: 400px;
	margin-right: 10px;
	margin-left: 10px;
}

.xBtn {
	padding: 8px 16px;
	margin-left: 12px;
}

.formWrapper {
	display: flex;
	align-items: flex-end;
	margin-left: 30px;
	gap: 20px;
}

.formWrapperAction {
	display: flex;
	align-items: flex-end;
	margin-bottom: 20px;
	gap: 20px;
}

.filterInput,
.filterBtn {
	width: 220px;
}

.articleGray {
	background-color: $lightGray;
	margin: 20px 10px;
}

.gridRow {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 350px));
	grid-gap: 15px 15px;
	justify-content: center;
	align-items: baseline;
	background-color: #fdfdfd;
	padding: 10px;
}

.gridItem {
	display: flex;
	flex-direction: column;
}

.gridRow .gridItem span {
	line-height: 1;
}

.fontBlue {
	color: $blue;
	margin-top: 5px;
}

.formLabel {
	margin: 10px 0;
	font-weight: 600;
}

.accordionResumo {
	display: flex;
	flex-direction: column;

	max-height: 400px;
	overflow-y: scroll;
}

.accordionStatus {
	display: flex;
	justify-content: space-around;
	margin-top: 20px;
}

.accordionStatusMarginRight {
	margin-right: 5px;

}

.divAnaliseQualidadeMargin,
.divDirecionamentoMargin {
	margin: 20px;
	width: 100%;
}

.modalMainWrapper {
	display: flex;
	gap: 40px;
	margin-top: 20px;
	justify-content: center;
}

.modalFormWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 30%;
}

.modalInfo {
	width: 70%;
}

.modalFormFirstRow {
	display: flex;
	gap: 20px;
	justify-content: space-between;
}

.modalFormBtn {
	width: 100%;
	// height: min-content;
	margin-top: auto;
}

.analiseWrapper {
	width: 100%;
	border: 1px solid #777777;
	border-radius: 5px;
	padding: 10px;
}

.divAnaliseDirecionamento {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 10px;
	justify-content: center;
}

.showFeedback {
	width: 100%;
	margin-top: 0.25rem;
	font-size: .875em;
	color: $red;
}

.bodyReceb {
	display: flex;
	justify-content: space-evenly;

}

.bodyReceb > div {
	display: flex;

}

.formGroup {
	min-width: 400px;
	min-height: 150px;
}

.texAreatLabel{
	margin-top: 20px;
}

.textAreaControl {
	height: 150px;
}

input:checked + label, input:checked + label {
	font-weight: bold;
}

.modalAprovado {
	font-weight: bold;
	color: green;
	text-align: center;
}

.modalReprovado {
	font-weight: bold;
	color: red;
	text-align: center;
}

.divRecebBtn {
	display: flex;
	justify-content: space-around;
}

.articleModal {
	margin: 0 20px;
}

.testesTitle {
	margin-bottom: 10px;
}

.divStack {
	margin-top: 10px;
	background-color: #F7F7F7;
}

.stackTestes {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	margin: 5px 10px;
}

.divDesc {
	min-width: 200px;
}

.divSelect {
	margin-left: 10px;
	width: 150px;
}

.divGramLarg {
	width: 200px;
}

.divObs {
	width: 500px;
}

.divObsGramLarg {
	width: 300px;
}

.divTolerancia {
	font-size: 17px;
	margin-bottom: 5px;
}