$lightGray: #F7F7F7;
$blue: #0D6EFD;
$invalRed: #DC3545;

.tableLines {
    background-color: red;
}

.form {
    display: flex;
    gap: 20px;
    margin: 0 24px;
    align-items: flex-end;
    flex-wrap: wrap;
}

.filterWidth {
    min-width: 180px;
}

.mairArrWrapper {
    margin: 24px;
}

.mainArrItem {
    position: relative;
    display: flex;
    gap: 6px;
    flex-direction: column;
    overflow-x: visible;
    border: 2px solid #00000090 !important;
    border-bottom: none !important;
}

.mainArrRow:last-of-type .mainArrItem {
    border-bottom: 2px solid #00000090 !important;
}

.mainArrItem * {
    font-size: 14px;
}

.mainArrInfoRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.nf_warn {
    color: #DC3545;
    font-size: 14px;
    font-weight: 500;
    padding-left: 6px;
}

.mainArrIcon {
    margin: 0px 10px;
}

.mainArrCol {
    width: 30%;
    align-self: flex-start;
}

.mainArrLabel {
    color: #00000090;
    font-weight: 500;
}

.flexFooter {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.flexFooter Button {
    width: max-content;
}

.modalBody {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.modalWarn {
    background-color: #fae25a50;
    padding: 10px 20px;
    border-radius: 15px;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.modalMain {
    padding: 0px 10px;
    font-size: 14px;
    font-style: italic;
    color: #000000;
    font-weight: 500;
}

.devolucaoWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.formGroupRow {
    display: flex;
    align-items: center;
}

.formGroupRow .check {
    width: 80px;
}

.formGroupRow .qtBox {
    width: 200px;
}