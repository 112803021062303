$lightGray: #F7F7F7;
$blue: #0D6EFD;
$red: #E90101;
$gray: #777777;
$invalRed: #DC3545;

.form {
    display: flex;
    align-items: flex-end;
}

.formGroup {
    margin: 10px;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px 10px;
}

.verNotaFiscal {
    display: flex;
    white-space: nowrap;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
    vertical-align: middle;
}

.icon {
    display: flex;
    align-items: center;
}

.fontBlue {
    color: $blue;
}

.fontRed {
    color: $red;
}

.monitorInfo {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}

.monitorInfo:first-of-type {
    padding-bottom: 16px;
    border-bottom: 1px solid #dee2e6;
}

.monitorInfo:nth-of-type(2) {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #dee2e6;
}

.monitorInfo:nth-of-type(3) {
    padding-top: 16px;
}

.infoBlock {
    width: 490px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.infoBlockColumm {
    display: flex;
    margin: 10px;
    flex-direction: column;
    height: 100%;
}

.modalWidth {
    min-width: 1000px;
}

.selectWidth {
    width: 100%;
}

.spanOpcional {
    font-size:13px;
    color: $gray;
    margin-left: 5px;
}

.btn {
    margin: auto 10px 10px;
}

.noResize {
    resize: none;
}

.showFeedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: $invalRed;
}

.hidden {
    display: none;
}

.filterWidth {
    width: 14%;
}

.opaque {
    opacity: 0.5;
}

.hoverBtn:hover {
    cursor: auto;
}

.btnLimit {
    margin: 0 auto;;
    width: 400px;
}

.flex {
    display: flex;
}