$lightGray: #F7F7F7;

.articleMargim {
    margin: 20px 10px;
}

.form {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
}

.formGroup {
    margin: 0 10px 0 0;
}

.articleGray {
    background-color: $lightGray;
    margin: 30px 20px 0px 20px;
    max-height: 650px;
    height:60vh;
    overflow: auto;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
    vertical-align: baseline;
}

.btnList {
    background-color: $lightGray !important;
    border: none !important;
}

.btnList:active:focus {
    outline: none;
    box-shadow: none;
}
