$lightGray: #F7F7F7;
$gray: #7777;
$blue: #0d6efd;

.articleInRow {
  display: flex;
  margin-top: 20px;
}

.partSection {
  width: 50%;
}

.sectionLeft {
  width: 50%;
  border-right: 1px solid $gray;
  margin: 0 5px;
}

.formBlock {
  display: flex;
  margin: 20px 0;
  align-items: flex-end;
  padding: 10px;
}

.selectsBlock {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.formgroup {
  margin: 0 10px;
}

.formLabeInput {
  font-weight: bold;
}

.tableBorder {
  border: solid thin;
  margin: 0px 10px;
  display: grid;
}

.tableGrupo {
  display: flex;
  margin: 20px 10px;
}

.tableGrupoLarge {
  margin: 20px 10px;
}

.itemCentral {
  text-align: center;
  margin: 20px;
}

.form {
  display: flex;
  align-items: flex-end;
  margin-left: 10px;
}

.formGroup {
  margin: 0 10px 0 0;
}

.tableGray {
  background-color: $lightGray;
  margin: 20px 10px;
  display: grid;
}

.blockTitle {
  font-weight: bold;
}

.tableLines {
  white-space: nowrap;
  cursor: pointer;
}

.rowSelected {
  background-color: $blue;
  color: white;
}

.rowNoSelected {
  background-color: transparent;
  color: black;
}

.btnMargin {
  margin: 20px;
}

.miniTable{
  border-radius: 4px;
  border: solid thin;
  margin: 0px 5px;
  display: grid;
  width: 33%;
}

table {
  width: 100% !important;
}

.iconCursor {
  cursor: pointer;
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  margin: 0px 16px;
  background-color: #f7f7f7;
  gap: 4px;
}

.flexWrapperCol {
  display: flex;
  padding: 16px 16px;
  margin: 0px 16px;
  background-color: #f7f7f7;
  gap: 20px;
}

.flexRow {
  display: flex;
  gap: 20px;
  width: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: auto;
  margin-right: auto;
}

.flexItem {
  min-width: 30%;
}

.formFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.formGroup {
  // width: calc(50% - 15px);
  // min-width: 200px;
  width: 100%;
}