$lightGray: #F7F7F7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;
$green: #01A34C;

.articleForm {
    display: flex;
}

.form {
    margin: 10px;
    display: flex;
}

.formGroup {
    display: flex;
    flex-direction: column;
    margin: 20px;
    min-width: 30vh;
}

.formLabel {
    font-weight: bold;
}

.formLabelGreen {
    font-weight: bold;
    color: $green;
}

.formLabelBlue {
    font-weight: bold;
    color: $blue;
}

.spanOpcional {
    font-weight: normal;
    font-size:13px;
    color: $gray;
    margin-left: 5px;
}

.titleMargin {
    margin: 30px 0px 0px 30px;
    font-weight: bold;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px;
}

.articleFlex {
    display: flex;
    flex-direction: column;
    margin: 0px 20px;
}



.footer {
    display: flex;
    position: fixed;
    height: 70px;
    background-color: #EFEFEF;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 5px 5px 5px 5px #00000040;
    
}

.btnFooter {
    margin: 20px 10px;
}

.divFormResumoSQ,
.formResumoSQ {
    display: flex;
}

.formGroupResumoSQ {
    margin: 0px 10px;
    font-weight: bold;
}

.formLabelResumoSQ {
    margin: 0px 10px;
    color: $gray;
}

.resumeTitleMargin {
    margin: 30px 0px 0px 10px;
    font-weight: bold;
}

.accordionFlex {
    display: flex;
    flex-direction: column;
}

.blockTitle {
    font-weight: bold;
    white-space: nowrap;
}

.tableLines {
    white-space: nowrap;
    cursor:pointer;
}

.widthModal {
    width: 80vw;    /* Occupy the 90% of the screen width */
    max-width: 80vw;
    height: 40vw;
    max-height: 40vw;
}

.modalFooter {
    background-color: #EFEFEF;
    width: 100%;
    justify-content: center;
}


//Tablet
@media screen and (min-width: 0px) and (max-width: 1023px) {
    .form {
        margin: 10px;
        display: flex;
        flex-direction: column;
    }

    .articleForm {
        display: flex;
        flex-direction: column;
    }

    .btnRightSide {
        text-align: right;
        margin: 20px;
        padding-bottom: 20px;
    }
    
    .btnWidth {
        padding: 10px 20px;
    }
}

//Desktop
@media screen and (min-width: 1024px) {
    .form {
        margin: 10px;
        display: flex;
    }

    .articleForm {
        display: flex;
    }

    .btnRightSide {
        text-align: right;
        margin: 20px;
        padding-bottom: 20px;
    }
    
    .btnWidth {
        padding: 10px 20px;
    }


}