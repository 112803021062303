$lightGray: #F7F7F7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;

.article-margin {
    margin: 20px 0px;
}

.form {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
}

.formGroup {
    margin: 0 10px 0 0;
}

.formBtnDisplay {
    display: flex;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px 10px;
}

.infoProduto {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.infoProdutoMargin {
    margin: 10px;
}

.infoProdutoLabelColumm {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.spanOpcional {
    font-size:13px;
    color: $gray;
    margin-left: 5px;
}

.fontRed {
    color: $red;
    font-weight: bold;
}

.fontBlue {
    color: $blue;
}

.opaque {
    opacity: 0.5;
}

.hoverBtn {
    cursor: pointer;
}

.flexWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
}

.formInput {
    width: 46%;
}

.column_wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.form_grade_wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin: 6.5px;
}

.form_grade_checkbox {
    min-width: 220px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.form_grade_number {
    max-width: 100px;
}

.invalid_red {
    color: red;
}