$lightGray: #F7F7F7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;
$green: #01A34C;
$invalRed: #DC3545;

.articleMargim {
    margin: 30px 10px;
}

.form {
    display: flex;
    align-items: flex-end;
}

.formGroup {
    margin-right: 20px;
}

.formSelectModal,
.formControlModal {
    width: 240px;
    // margin-bottom: 20px;
}

.formLabel {
    font-weight: bold;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px 10px;
    min-height: 20vh;
    max-height: 20vh;
    overflow: auto;
}

.footer {
    display: flex;
    position: fixed;
    height: 50px;
    background-color: $lightGray;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 5px 5px 5px 5px #00000040;
}