$lightGray: #F7F7F7;
$blue: #0D6EFD;
$gray: #777777;

.articleMargim {
    margin: 30px 10px;
}

.form {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
}

.formGroup {
    margin: 0 10px 0 0;
    width: 38vh;
}

.formLabel {
    font-weight: 500;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px 20px;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
}

.article-margin {
    margin: 20px 0px;
}

.fontBlue {
    color: $blue;
}

.monitorInfo {
    display: flex;
}

.infoBlock {
    display: flex;
    flex-direction: column;
}

.infoBlockColumm {
    display: flex;
    margin: 10px;
    flex-direction: column;
}

.formGroupModal {
    margin-bottom: 10px;
    width: 100%;
}

.modalFooter {
    background-color: $lightGray;
    justify-content: center;
}

.iconCursor {
    cursor: pointer;
}

.spanOpcional {
    font-size:13px;
    color: $gray;
    margin-left: 5px;
}