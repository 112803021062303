$lightGray: #F7F7F7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;
$invalRed: #DC3545;

.form {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
}

.formGroup {
    margin: 0 20px 0 10px;
}

.formColumm {
    display: flex;
    flex-direction: column;
    margin: 10px 20px 20px 10px;
}

.formGroupRow {
    display: flex;
}

.divFlexRow {
    display: flex;
}

.formLabel {
    margin: 10px 0 ;
    font-weight: 600;
}

.modalFormLabel {
    margin-bottom: 10px;
    font-weight: 600;
}

.formBtn {
    margin: 30px 0 0 10px;
}

.formGroupDate {
    margin-right: 20px;
}

.formTitle {
    margin-left: 10px;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px 20px;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
}

.tableTitle {
    padding: 50px 0px 0px 20px;
}

.footer {
    display: flex;
    position: fixed;
    height: 7%;
    background-color: #EFEFEF;
    bottom: 5px;
    left: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.infoBlockDisplay{
    display: flex;
    justify-content: space-around;
}

.infoBlock {
    display: flex;
    justify-content: space-around;
}

.infoBlockColumm {
    display: flex;
    margin: 10px;
    flex-direction: column;
    width: 30vh;
}

.fontBlue {
    color: $blue;
}

.modalBodyObservacao {
    margin: 20px 0px;
    font-style: italic;
    font-size: larger;
}

.radioBtnList {
    background-color: white;
    border-radius: 4px;
    border: 2px solid white;
    padding: 10px;
    min-width: 130px;
    height: 300px;
    overflow-y: scroll;
}

.radioBtnListRed {
    background-color: white;
    border-radius: 4px;
    border: 2px solid $invalRed;
    border-color: $invalRed;
    padding: 10px;
    min-width: 120px;
    height: 300px;
    overflow-y: scroll;
}

.articleGrayFlex {
    background-color: $lightGray;
    margin: 20px 10px;
    padding: 10px 20px 20px;
    display: flex;
    justify-content: space-between;
    // gap: 20px;
    align-items: flex-start;
    border-radius: 4px;
}

.articleFlexRow {
    display: flex;
}

.modalFooter {
    justify-content: center;
}

.formWrapper {
    display: flex;
    align-items: flex-end;
    margin-left: 20px;
    gap: 20px;
}

.formGroup {
    margin: 0 20px 0 10px;
}

.filterInput,
.filterBtn {
    width: 220px;
}

.tableStyle {
    min-height: 260px;
    height: 310px;
    overflow-y: scroll;
}

.formModal {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.showFeedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: $invalRed;
}

.hidden {
    display: none;
}

.formModalWrapper {
    display: flex;
    gap: 40px;
    padding-top: 10px;
}

.tableWrapper {
    height: 330px;
    overflow-y: scroll;
    box-sizing: border-box; 
    width: 50%;
}