$lightGray: #F7F7F7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;

.flexWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 20px;
}

.flexWrapper > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.form {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 20px;
}

.form > div {
  display: flex;
  width: 100%;
  gap: 20px;
}

.flexRow {
  display: flex;
  gap: 20px;
}

.tableWrapper {
  width: 100%
}

.formWrapper {
  width: 100%;
}

.formLabel {
  font-weight: 500;
}

.spanOpcional {
  font-size:13px;
  color: $gray;
  margin-left: 5px;
}

.formTextWrapper {
  display: flex;
  flex-direction: column;
  width: '100%';
  gap: 0px !important;
}

.btnWidth {
  width: 300px;
}

.removeBtn {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  background-color: red;
  color: red;
}