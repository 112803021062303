.form {
  display: flex;
  margin: 10px 20px 20px;
  gap: 20px;
}

.form_group {
  display: block;
}

.form_group_btn {
  font-size: 13px;
  font-weight: 500;
  align-self: flex-end;
  width: 100px;
}

.filter_label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #000000AE;
}

.filter_control {
  font-size: 13px;
}