$blue: #0D6EFD;
$lightGray: #F7F7F7;
$red: #DC3545;

.formWrapper {
	display: flex;
	align-items: flex-end;
	margin-left: 20px;
	gap: 20px;
}

.formWrapperAction {
	display: flex;
	align-items: flex-end;
	margin-bottom: 20px;
	gap: 20px;
}

.filterInput,
.filterBtn {
	width: 220px;
}

.articleGray {
	background-color: $lightGray;
	margin: 20px 10px;
}

.gridRow {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 350px));
	grid-gap: 15px 15px;
	justify-content: center;
	align-items: baseline;
	background-color: #fdfdfd;
	padding: 10px;
}

.gridItem {
	display: flex;
	flex-direction: column;
}

.gridRow .gridItem span {
	line-height: 1;
}

.fontBlue {
	color: $blue;
	margin-top: 5px;
}

.formLabel {
	margin: 10px 0;
	font-weight: 600;
}

.accordionResumo {
	display: flex;
	flex-direction: column;

	max-height: 400px;
	overflow-y: scroll;
}

.accordionStatus {
	display: flex;
	justify-content: space-around;
	margin-top: 20px;
}

.accordionStatusMarginRight {
	margin-right: 5px;

}

.divAnaliseQualidadeMargin,
.divDirecionamentoMargin {
	margin: 20px;
	width: 100%;
}

.modalMainWrapper {
	display: flex;
	gap: 40px;
	margin-top: 20px;
	justify-content: center;
}

.modalFormWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 30%;
}

.modalInfo {
	width: 70%;
}

.modalFormFirstRow {
	display: flex;
	gap: 20px;
	justify-content: space-between;
}

.formGroup {
	min-width: 180px;
}

.modalFormBtn {
	width: 100%;
	// height: min-content;
	margin-top: auto;
}

.analiseWrapper {
	width: 100%;
	border: 1px solid #777777;
	border-radius: 5px;
	padding: 10px;
}

.divAnaliseDirecionamento {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 10px;
	justify-content: center;
}

.showFeedback {
	width: 100%;
	margin-top: 0.25rem;
	font-size: .875em;
	color: $red;
}

@media screen and (min-width: 1024px) {
	.divFlexRow {
		display: flex;
		background-color: #fdfdfd;
		padding: 10px;
		align-items: center;
		height: 20vh;
	}

	.divGrupoAnalise {
		display: flex;
		width: 160%;
	}

	.divButton {
		display: flex;
		width: 100%;
		height: fit-content;
		align-items: flex-end;
		position: static;
		align-self: flex-end;
		margin-bottom: 30px;
	}

	.divGrupoAnaliseTable,
	.divOrientacaoForm,
	.divButtonBtn {
		width: inherit;
		margin: 0px 30px;
	}

	.articleGrayRow {
		background-color: $lightGray;
		margin: 20px 10px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.articleItemMargin {
		margin: 30px;
	}
	
	.articleItemMarginPecas {
		margin: 30px;
		height: fit-content;
	}
	
	.articleItemMarginBottom {
		display: flex;
		align-items: flex-end;
		margin-right: 30px;
		margin-bottom: 30px;
	}
	
	.articleItemWhite {
		background-color:white;
		height: 23vh;
		overflow: auto !important;
	}
}

@media screen and (min-width: 0px) and (max-width: 1023px) {
	.modalMainWrapper {
		flex-direction: column;
	}

	.modalFormWrapper {
		width: 100%;
	}

	.modalFormFirstRow > .formGroup {
		width: 100%;
	}

	.modalInfo {
		width: 100%;
	}

	.formWrapper {
		display: flex;
		align-items: flex-end;
		margin: 20px;
		gap: 10px;
	}

	.divFlexRow {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(100px, 750px));
		grid-gap: 15px 15px;
		align-items: baseline;
		background-color: #fdfdfd;
		padding: 10px;
		justify-content: center;
	}

	.divTable {
		display: flex;
		flex-direction: column;
		border: 1 solid #7777;
		border-radius: 5px;
		padding: 5px;
	}

	.divButton {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
	}

	.divAnaliseQualidade {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(100px, 750px));
		grid-gap: 15px 15px;
		align-items: baseline;
		background-color: #fdfdfd;
		padding: 10px;
		justify-content: center;
	}

	.accordionResumo {
		max-height: max-content;
	}

	.articleGrayRow {
		background-color: $lightGray;
		margin: 20px 0px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	
	.articleItemMarginPecas {
		margin: 10px;
	}
	
	.articleItemMarginBottom {
		display: flex;
		justify-content: center;
		margin: 10px 10px 20px 10px;
	}
	
	.articleItemWhite {
		background-color:white;
		width: 100%;
		overflow: auto !important;
		height: 15vh;
		overflow: auto !important;
	}

	.filterInput {
		width: 100%;
		padding: 10px;
	}
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
    cursor:pointer;
}

.blockTitleEditar {
    font-weight: bold;
}

.blockTitlePendenciaDefeitos {
    font-weight: bold;
	width: 30%;
}

.tableLinesEditar {
    white-space: nowrap;
    cursor:pointer;
}

.tablePendenciar {
	margin-top: 20px;
	height: 47vh;
}

.tableLinesBlue {
    white-space: nowrap;
    cursor:pointer;
	color: blue;
}

.tableLinesRed {
    white-space: nowrap;
    cursor:pointer;
	color: red;
}

.icon {
    display: flex;
    align-items: center;
}

.iconNoBorder {
    display: flex;
    align-items: center;
	border-style: hidden;
}

.fontRed {
    color: $red;
}

.modalFooter {
    background-color: $lightGray;
    justify-content: space-around;
	display: flex;
}

.infoBlockDisplay{
    display: flex;
    justify-content: space-around;
}

.infoBlock {
    display: flex;
    flex-direction: row;
	width: 500px;
	justify-content: space-around;
}

.infoBlockColumm {
    display: flex;
    margin: 10px;
    flex-direction: column;
	justify-content: space-around;
}

.infoBlockDisplayPend {
    display: flex;
	flex-direction: column;
    justify-content: space-around;
}

.infoBlockPend {
    display: flex;
	justify-content: space-around;
}

.infoBlockColummPend {
    display: flex;
    margin: 10px;
	width: 220px;
    flex-direction: column;
	justify-content: space-around;
}

.articleSpacing {
    margin-bottom: 10px;
}

.formModal{
    align-items: flex-end;
    gap: 20px;
}

.formLabel {
    font-weight: 600;
    margin: 10px 0 ;
}

.formGroup {
    width: 200px;
}

.formGroupModal {
    margin: 15px auto;
    width: 300px;
}

.formGroupTextarea {
    width: 350px;
    height: 250px;
    resize: none !important;
    margin: 30px auto;
}

.avisoConfirmacao {
	text-align: center;
	margin-top: 10px;
	margin-bottom: 20px;
}

.avisoAtencao {
	color: red;
}

.disabledFormPend {
	border: none;
}

.modalBtn{
	width: 100px;
	margin: 0 5px;
}

.divButtons{
	margin: 30px 0;
	display: flex;
	justify-content: space-around;
}

.mainBtnEnv {
	width: 170px;
}

.mainTdBtn {
	width: 200px;
}