* {
  font-size: 13px;
}

.revisao_body_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal_body,
.defeitos_form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.flex_section {
  display: flex;
  gap: 16px;
}

.flex_section_column {
  display: flex;
  flex-direction: column;
}

.select_group {
  font-size: 13px;
}

.form_label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #000000AE;
}

.filter_btn {
  font-size: 13px;
  font-weight: 500;
  width: 100px;
  align-self: flex-end;
  justify-self: flex-end;
  width: auto;
}

.warn_text {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 8px;
  margin: auto;
}

.modal_width {
  width: 800px;
}

.accordion_body {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 16px;
  font-size: 14px;
  justify-content: left;
}

.accordion_body2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  padding: 16px;
  font-size: 14px;
}

.accordion_section {
  display: flex;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: left;
}

.accordion_section2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: left;
}

.accordion_item {
  min-width: 30%;
  font-weight: 500;
  width: min-content;
  margin-right: auto;
}

.accordion_item_big {
  min-width: 100%;
  font-weight: 500;
  width: min-content;
  text-align: center;
}

.font_blue {
  color: #0D6EFD;
  font-weight: 500;
}

.font_blue_big {
  color: #0D6EFD;
  font-weight: 500;
  font-size: 15px;
}

.modal_footer {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
}

.footer_btn_processamento,
.footer_btn_direcionamento {
  border: 1px solid #dadada !important;
  font-weight: 500;
  padding: 10px;
  min-width: 200px;
}

.btn_preco_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.btn_preco {
  border: 1px solid #dadada !important;
  font-weight: 500;
  padding: 10px;
}

.backdrop_z_override {
  z-index: 1055;
}

.list_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.list_item {
  box-sizing: border-box !important;
  font-weight: 500;
  // Por algum motivo incompreensível a propriedade gap não está funcionando corretamente com a largura dos elementos internos;
  width: calc(33.33% - 10.66px);
  margin: 0px;
  border: 1px solid #dadada !important;
  border-radius: 4px !important;
}

.list_item.disabled.warning {
  background-color: red !important;
}

.modo_ajuste {
  text-decoration: underline;
}

.flex_title {
  display: flex;
  margin: 10px 20px 0px;
}

.title {
  color: #000000AE;
}

.separator {
  background-color: #dee2e6;
  height: 1px;
  width: 100%;
}

.dados_analise_wrapper {
  border: 1px solid #dee2e6;
  flex-direction: column;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  gap: 16px;
  margin: 0px auto;
  max-width: 748px;
}

.dados_analise_item {
  margin: auto;
}

.dados_analise_item_duplo {
  display: flex;
  justify-content: center;
  gap: 16px;
}


.dados_analise_item *,
.dados_analise_item_duplo {
  text-align: center;
}

.dados_analise_item_group {
  gap: 16px;
  display: flex;
  margin: 0 auto;
}

.status_box {
  padding: 8px 16px;
  border-radius: 16px;
  font-weight: 500;
  font-style: 14px;
}

.analise_info {
  font-weight: 500;
  font-style: 14px;
}

.sub_form_label {
  display: block;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #000000AE;
}

.resumo_row {
  display: flex;
  gap: 16px;
}

.resumo_item {
  width: 50%;
}

.font_red {
  color: #ff6c6c;
}

.direcionamento_info,
.direcionamento_info_warn {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.direcionamento_info_warn {
  color: brown;
}

.preco_switch {
  align-content: center;
  align-items: center;
}

.preco_input {
  width: 50%;
}

.dados_analise_divergencias {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
}

.header_section {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 0px 20px 0;
  gap: 16px;
}

.adm_btn_wrapper {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  // width: 100%;
}

.filter_wrapper {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.filter_group {
  width: 164px;
  display: block;
}

.filter_label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #000000AE;
}

.filter_control {
  min-height: 38px;
  font-size: 13px;
}

.select_comp {
  width: 33%;
}

.dados_conferencia {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: left;
}

.defeitos_inseridos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.defeitos_inseridos_warning {
  text-align: center;
  background-color: #e6410030;
  border-radius: 6px;
  padding: 6px 12px;
  width: calc(33.33% - 10.66px);
}

.defeitos_inseridos_item {
  position: relative;
  background-color: #e6b8004d;
  border-radius: 6px;
  padding: 6px 12px;
  width: calc(33.33% - 10.66px);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  // overflow: hidden;
}

.defeitos_inseridos_text {
  text-align: center;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.defeitos_inseridos_text_separator {
  display: inline-block;
  text-align: center;
  padding: 0 10px;
}

.defeito_remove_btn {
  align-items: center;
  display: flex;
  position: absolute;
  right: -6px;
  top: -6px;
  cursor: pointer;
}

.input_label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: transparent;
}

.images_arr {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: flex-start;
  overflow: hidden;
}

.remove_btn {
  align-items: center;
  display: flex;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  background-color: #f0f0f0;
  border-bottom-left-radius: 4px;
}

.loc_label_true, .loc_label_false {
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
}

.loc_label_true {
  background-color: #B4E380;
}

.loc_label_false {
  background-color: #FF7777;
}

.btn_big_header {
  align-self: flex-end;
  font-weight: 500;
  min-height: 38px;
}

.vertical_separator {
  background-color: #dee2e6;
  width: 1px;
  height: 100%;
}

.chamados_wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
}

.chamados_list {
  width: 100%;
}
