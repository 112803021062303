$lightGray: #F7F7F7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;

.headerWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
}

.formWrapper,
.actionWrapper {
    display: flex;
    gap: 20px;
}

.form {
    display: flex;
    align-items: flex-end;
    gap: 20px;
}

.formRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.formLabel {
    font-weight: 600;
    margin: 10px 0 ;
}

.formGroup {
    width: 200px;
}

.formGroupCheck {
    display: flex;
    gap: 20px;
    align-items: center;
    padding-top: 6px;
}

.incArticleWrapper {
    display: flex;
    flex-direction: column;
    margin: 20px 20px 100px;
    gap: 20px;
}

.incIDWrapper {
    display: flex;
    gap: 20px;
}

.articleGray {
    background-color: $lightGray;
    width: 100%;
    // padding: 20px 20px 0px 20px;
    padding: 20px;
    // border-bottom: 20px solid $lightGray;
    border-radius: 10px;
    max-height: 320px;
    min-height: 320px;
    overflow: hidden;
}

.incTableWrapper {
    overflow-y: scroll;
    min-height: 249px;
    max-height: 249px;
    position: relative;
}

.articleWhiteTable {
    background-color: white;
    margin: 0px;
    min-height: 149px;
    width: 100%;
    table-layout: auto;
}

.articleGrayAlt {
    background-color: $lightGray;
    width: auto;
    padding: 20px;
    border-radius: 10px;
    overflow: scroll;
    margin: 20px;
    box-sizing: border-box;
}

.blockTitle {
    font-weight: bold;
}

.tableLinesCheck {
    text-align: center;
}

.tableLines {
    white-space: nowrap;
    vertical-align: middle;
}

.footer {
    display: flex;
    position: fixed;
    height: 7%;
    background-color: #EFEFEF;
    bottom: 5px;
    left: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.fontBlue {
    color: $blue;
}

.observacaoTela {
    margin: 30px 20px;
    font-style: italic;
    font-size: large;
}

.switchLiberarRiscoSacado {
    margin: 20px;
    font-size: large;
    font-weight: 700;
}

.formMotivo {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
}

.formGroupMotivo {
    margin: 0 20px 0 10px;
    width: 50vh;
}

.spanOpcional {
    font-size:13px;
    color: $gray;
    margin-left: 5px;
}

.tituloIdentificacao {
    margin: 30px 20px 20px 40px;
    font-size: large;
}

.identificacaoEntradaEstoqueGrade {
    display: flex;
}

.hidden {
    display: none;
}

.modalBtn {
    width: 100px;
}

.modalFooter {
    display: flex;
    justify-content: space-around;
}

.red {
    color: $red;
}