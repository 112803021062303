.customTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  thead {
    tr {
      background-color: #527ac5;
      border-bottom: 1px solid #ddd;
    }
  }

  th, td {
    width: 131.25px;
    padding: 0px 16px;
    text-align: center;
    height: 52px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  th {
    color: #FFFFFF;
    font-weight: 500;
  }

  td {
    height: 48px;
  }

  tbody {
    tr {
      border-bottom: 1px solid #ddd;
      cursor: pointer;

      &:nth-child(odd) {
        background-color: #FAFAFA;
      }

      &:nth-child(even) {
        background-color: #FFFFFF;
      }

      &:hover {
        background-color: #EEEEEE;
      }

      &.prioritized {
        background-color: #FFF8DC;
      }

      &.prioritized:nth-child(odd) {
        background-color: #FFF8DA;
      }

      &.prioritized:nth-child(even) {
        background-color: #FFFBEA;
      }
    }
  }

  button {
    padding: 5px 10px;
    cursor: pointer;
  }
}

.datatable_wrapper {
  overflow-x: auto;
  max-width: 100%;
  border: 1px solid #00000030;
  margin: 0px;
}

.prioridade_wrapper {
  padding: 4px 4px;
  background-color: #FFC107;
  margin: 0 30px;
  border-radius: 10px;
  font-weight: 500;
}