// MONITOR REVISAO ANALISE GRADE MODULE SCSS


.articleMargin,
.modalCollapseMargin {
    margin-bottom: 10px;
}

.collapseTitle {
    background-color:#C4C4C4;
    margin: 0 auto;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.collapseDiv {
    display: flex;
    justify-content: space-around;;
    background-color: #d3d3d340;
    font-style: italic;
    align-items: flex-start;
}

.spanBlueBold {
    color: #0D6EFD;
}

.spanRed {
    color: red;
    font-weight: bold;
}

.spanGray {
    color: #777777;
}

.fontBold {
    font-weight: bold;
    white-space: nowrap;
}

.article-margin {
    margin-bottom: 10px;
}

.articleBorder {
    border: 1px solid #777777;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 50px;
}

.formColumm {
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
}

.alignLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px;
}

.formAlignLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.buttonRight {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
}

.buttonMarginSide {
    margin: 0 10px;
}

.marginTop {
    margin-top: 20px;
}

.articleGray {
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    background-color: rgba(196, 196, 196, 0.17);
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
}

.articleGrayColumn {
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    background-color: rgba(196, 196, 196, 0.17);
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.displayTwoTablesLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 50%;
    overflow: auto;
}

.fontSmall {
    font-size: 12px;
    margin-left: 10px;
    white-space: nowrap;
}

.divAlignLine {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.footer {
    display: flex;
    position: fixed;
    height: 50px;
    background-color: #EFEFEF;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.formAlignLine {
    display: flex;
}

.alignColummFormDefeito {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    min-width: 30%;
}

.alignLineFormDefeito {
    display: flex;
    justify-content: flex-start;
}

.sectionBorder {
    border: 1px solid #7777;
    border-radius: 4px;
    margin: 20px 0px;
}

.backgroundMiddleGray {
    background-color: #EDEDED;
    padding: 5px;
}

.secondQaInfo {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
}

.formSecondQaInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.spanBold {
    font-weight: bold;
}

.sectionContent {
    //width: auto;
    margin: 20px;
}

.spanOpcional {
    color: #777777;
    font-size: 14px;
    padding-left: 5px;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
    cursor:pointer;
}

.modalFooter {
    background-color: #EFEFEF;
    width: 100%;
    justify-content: center;
    box-shadow: 5px 5px 5px 5px #00000040;
}