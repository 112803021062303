$blue: #0D6EFD;
$lightGray: #F7F7F7;
$red: #DC3545;

.articleGray {
	background-color: $lightGray;
	margin: 20px 10px;
}

.articleTitle{
	display: flex;
	justify-content: space-between;
}

.modalBtn{
	width: 100px;
	height: 33px;
	margin: 14px 33px;
}

.modalFooter {
    background-color: #EFEFEF;
    width: 100%;
    justify-content: center;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.form_label {
	font-size: 13px;
	font-weight: 500;
	margin: 4px 20px 4px 4px;
	color: #000000AE;
  }