$lightGray: #F7F7F7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;

.form {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.formGroup {
    margin: 0 10px 10px 0;
}

.articleGray {
    background-color: $lightGray;
    margin: 20px 10px;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
}

.article-margin {
    margin: 20px 0px;
}

.formFlex {
    display: flex;
    flex-direction: column;
    margin: 2px;
}

.fontBlue {
    color: $blue;
}

.fontRed {
    color: $red;
    font-weight: bold;
}

.fontBold {
    font-weight: bold;
}

.monitorInfo {
    display: flex;
    justify-content: space-around;
}

.infoBlock {
    display: flex;
    flex-direction: column;
}

.infoBlockColumm {
    display: flex;
    margin: 10px;
    flex-direction: column;
}

.discountAmount {
    color: #0D6EFD;
    font-size: 18px;
    font-weight: bold;
}

.modalFooter {
    background-color: #F7F7F7;
    justify-content: center;
}

.discountFormWidth {
    width: 320px;
}

.iconCursor {
    cursor: pointer;
}

.sectionContent {
    //width: auto;
    margin: 20px;
    overflow: auto;
    max-height: 30vh;
}

.divAlignLine {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.accordionTables {
    display: flex;
    justify-content: space-between;
}

.spanOpcional {
    font-size:13px;
    color: $gray;
    margin-left: 5px;
}

.formChecksRow {
    display: flex;
}

.formLabel {
    font-weight: 500;
}

.modalFormGroup {
    width: 100%;
}

.formTextArea {
    width: 100%;
}

.modalFormRow {
    width: 100%;
    margin-top: 20px;
    display: flex;
    gap: 40px;
}

.modalDefeitosTable {
    width: 100%;
    height: 300px;
    overflow-y: scroll;
}

.modalForm {
   width: calc(50% - 10px);
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.modalDefeitosTableInner {
    height: 50px;
    overflow: scroll;
}