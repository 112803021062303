* {
  font-size: 13px;
}

.modal_body,
.defeitos_form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.flex_section {
  display: flex;
  gap: 16px;
}

.select_group {
  font-size: 13px;
}

.form_label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #212529;
}

.filter_btn {
  font-size: 13px;
  font-weight: 500;
  width: 100px;
  align-self: flex-end;
  justify-self: flex-end;
  width: auto;
}

.warn_text {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 8px;
  margin: auto;
}

.modal_width {
  width: 800px;
}

.accordion_body {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 16px;
  font-size: 14px;
  justify-content: left;
}

.accordion_body2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  padding: 16px;
  font-size: 14px;
}

.accordion_section {
  display: flex;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: left;
}

.accordion_section2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: left;
}

.accordion_item {
  min-width: 30%;
  font-weight: 500;
  width: min-content;
  margin-right: auto;
}

.font_blue {
  color: #0D6EFD;
  font-weight: 500;
}

.modal_footer {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
}

.footer_btn_direcionamento {
  border: 1px solid #dadada !important;
  font-weight: 500;
  padding: 10px;
  min-width: 300px;
}

.footer_btn_processamento {
  border: 1px solid #dadada !important;
  font-weight: 500;
  padding: 10px;
  min-width: 300px;
}

.backdrop_z_override {
  z-index: 1055;
}

.list_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.list_item {
  box-sizing: border-box !important;
  font-weight: 500;
  // Por algum motivo incompreensível a propriedade gap não está funcionando corretamente com a largura dos elementos internos;
  width: calc(33.33% - 10.66px);
  margin: 0px;
  border: 1px solid #dadada !important;
  border-radius: 4px !important;
}

.list_item.disabled.warning {
  background-color: red !important;
}

.modo_ajuste {
  text-decoration: underline;
}

.flex_title {
  display: flex;
  margin: 10px 20px 0px;
}

.title {
  color: #000000AE;
}

.separator {
  background-color: #dee2e6;
  height: 1px;
  width: 100%;
  margin: 10px 0px;
}