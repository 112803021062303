$lightGray: #F7F7F7;
$gray : #dee2e6;
$darkGray: #777777;
$blue: #0D6EFD;
$red: #BB271A;

.articleMargin,
.modalCollapseMargin {
    margin-bottom: 10px;
}

.collapseTitle {
    background-color:$lightGray;
    margin: 0 auto;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.collapseDiv {
    display: flex;
    justify-content: space-around;
    font-style: italic;
    align-items: flex-start;
}

.collapseDivDiv {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.spanBlueBold {
    color: $blue;
}

.spanRed {
    color: $red;
    font-weight: bold;
}

.spanGray {
    color: $darkGray;
}

.fontBold {
    font-weight: bold;
    white-space: nowrap;
}

.article-margin {
    margin-bottom: 10px;
}

.articleBorder {
    border: 1px solid $darkGray;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 50px;
}

.formColumm {
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
}

.alignLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px;
}

.formAlignLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.buttonRight {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
}

.buttonMarginSide {
    margin: 0 10px;
}

.marginTop {
    margin-top: 20px;
}

.articleGray {
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    background-color: rgba(196, 196, 196, 0.17);
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
}

.displayTwoTablesLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 50%;
    align-items: center;
    overflow: auto;
}

.displayTwoTablesRight {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 50%;
    align-items: center;
    overflow: auto;
    margin-left: 5px;
}

.fontSmall {
    font-size: 12px;
    margin-left: 10px;
    white-space: nowrap;
}

.divAlignLine {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.footer {
    display: flex;
    position: fixed;
    height: 50px;
    background-color: $lightGray;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.formAlignLine {
    display: flex;
}

.alignColummFormDefeito {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    min-width: 30%;
}

.alignLineFormDefeito {
    display: flex;
    justify-content: flex-start;
}

.sectionBorder {
    border: 1px solid $darkGray;
    border-radius: 4px;
    margin: 20px 0px;
}

.backgroundMiddleGray {
    background-color: $lightGray;
    padding: 5px;
}

.secondQaInfo {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
}

.formSecondQaInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.spanBold {
    font-weight: bold;
}

.sectionContent {
    //width: auto;
    margin: 20px;
}

.spanOpcional {
    color: $darkGray;
    font-size: 14px;
    padding-left: 5px;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
    cursor:pointer;
}

.modalFooter {
    background-color: $lightGray;
    width: 100%;
    justify-content: center;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.footerBtn {
    margin: 0px 10px;
}

.radioButtonListStyle {
    min-height: 20vh;
    height: 100%;
    overflow: scroll;
    border: 1px solid $gray;
    border-radius: 4px;
    padding: 5px;
}

.radioButtonForm {
    min-height: 260px;
    height: 26vh;
}

.formLabel {
    font-weight: 600;
}