$lightGray: #f7f7f7;
$gray: #777777;
$blue: #0D6EFD;
$red: #E90101;
$fieldYellow: #fde8a3;
$divisionYellow: #ac9b66;

.bg-lightgray-table {
    background-color: $lightGray;
    border-radius: 0.25rem!important;
    border: 1px solid #dee2e6!important;
    margin-top: 20px;
}

.bg-lightgray-filtro {
    background-color: $lightGray;
    border-radius: 0.25rem!important;
    border: 1px solid #dee2e6!important;
    margin-top: 20px;
    padding: .5rem!important;
    display: flex!important;
    justify-content: space-between!important;
}

.fieldYellow {
    background-color: $fieldYellow !important;
    margin: 3px;
    overflow: hidden;
    max-width: 100px;
    min-width: 100px;
    border-left: 1px solid;
    border-color: $divisionYellow;
    font-size: large;
}

.selectYellow {
    background-color: $fieldYellow !important;
    border-color: $divisionYellow;
}

.sticky-th{
  background-color: $lightGray;
  position: sticky;
  top: 0;
}

.spaced-td{
    padding-right: 40px !important;
}

.tableLines {
    white-space: nowrap;
}

.article-margin {
    margin: 20px 0px;
}

.formFlex {
    display: flex;
    flex-direction: column;
    margin: 2px;
}

.fontBlue {
    color: $blue;
}

.monitorInfo {
    display: flex;
}

.infoBlock {
    display: flex;
    flex-direction: column;
}

.infoBlockColumm {
    display: flex;
    margin: 10px;
    flex-direction: column;
}

.discountAmount {
    color: #0D6EFD;
    font-size: 18px;
    font-weight: bold;
}

.modalFooter {
    background-color: #F7F7F7;
    justify-content: center;
}

.discountFormWidth {
    width: 320px;
}

.iconCursor {
    cursor: pointer;
}
