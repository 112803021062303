$lightGray: #F7F7F7;

.articleMargim {
    margin: 30px 10px;
}

.form {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
}

.formGroup {
    margin: 0 10px 0 0;
}

.articleGray {
    background-color: $lightGray;
    margin: 30px 20px 0px 20px;
    max-height: 650px;
    height:640px;
    overflow: auto;
}

.blockTitle {
    font-weight: bold;
}

.tableLines {
    white-space: nowrap;
}

.mainArrItem {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  z-index: 99;
  border: 2px solid #00000090 !important;
  border-bottom: none !important;
}

.mainArrItem * {
  font-size: 14px;
}

.mainArrInfoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.mainArrIcon {
  margin-right: 10px;
}

.mainArrCol {
  width: 30%;
  align-self: flex-start;
}

.mainArrLabel {
  color: #00000090;
  font-weight: 500;
}