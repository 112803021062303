$blue: #0D6EFD;
$lightGray: #F7F7F7;
$red: #DC3545;

.articleGray {
	background-color: $lightGray;
	margin: 20px 10px;
}

.articleTitle{
	display: flex;
	justify-content: space-between;
}

.modalBtn{
	width: 100px;
	height: 33px;
	margin: 14px 33px;
}

.modalFooter {
    background-color: #EFEFEF;
    width: 100%;
    justify-content: center;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.form_label {
	font-size: 13px;
	font-weight: 500;
	margin: 4px 20px 4px 4px;
	color: #000000AE;
}

.formWrapperModal {
	display: flex;
	align-items: flex-end;
}

.filterInputModal {
	width: 60%;
	padding: 10px 0;
	margin: 10px auto;
}

.modalBodyFilter {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.btnFiltro {
	width: 150px;
	margin: 5px 0;
}

.formGroupFiltro {
	display: flex;
	align-items: center;
	justify-items: center;
}

.floatingLabel {
	width: 100%;
}

.btnFiltroUnique {
	width: 200px;
	height: 33px;
	margin: 14px 33px;
}

.infoBlockDisplay{
    display: flex;
    justify-content: space-around;
}

.infoBlockColumm {
    display: flex;
    margin: 10px;
    flex-direction: column;
}

.fontBlue {
    color: $blue;
}

.articleGrade {
	display: flex;
	flex-direction: column;
	margin: 5px 0;
}

.articleGrade > div {
	margin: 5px 0;
	margin-top: 10px;
}

.articleGrade > div > span {
	display: inline-block;
	width: 20px;
	font-weight: 600;
}

.articleGrade > div > * {
	margin: 0 10px;
}

.divBtnIniciado {
	display: flex;
	flex-direction: column;
	width: 30%;
	margin: auto;
}

.btnIniciado {
	margin: 20px 0;
	height: 100px;
	font-size: xx-large;	
}

.setupBtn{
	width: 100px;
	height: 50px;
	margin: 14px 33px;
}