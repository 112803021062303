$lightGray: #F7F7F7;

.articleInRow {
  display: flex;
  align-items: stretch;
}

.blockNoborder {
  margin: 10px;
  width: -webkit-fill-available;
  display: grid;
}

.codArara {
  font-size: 50px;
  color: blue;
  font-weight: bold;
}

.salaAmostraCodArara {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.modalBody {
  display: flex !important;
  flex-direction: row !important;
}

.marginSelectsModal {
  margin: inherit;
}

.bottomStyle {
  padding: 10px;
  align-items: flex-end;
};

.displayContent {
  display: contents;
}

.alignBtn {
  align-self: self-end;
  margin: 10px 0;
}

.alignColummBottom {
  display: flex;
  flex-direction: column;
  align-self: self-end;
  margin: 10px 0;
}

.formGroupMargin {
  margin: 10px 0;
}

.articleGray {
  background-color: $lightGray;
  margin: 20px 10px;
}

.formEditModal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  // flex-wrap: nowrap;
}

.formRowEditModal {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
}

.formGroupEditModal {
  width: 100%;
}

.capLabel {
  font-weight: 700;
  font-size: 16px;
}

.editFooterInfo {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.editFooterInfo:first-child {
  margin-bottom: 10px;
}