.input-text {
  padding-left: 5px;
  vertical-align: middle;
  height: 2.3rem;
  margin-right: 0.5rem;
}

.col{
  text-align: center;
  vertical-align: middle;
}

.pedido{
  cursor: pointer;
}

.clickable{
  cursor: pointer;
}

.selected{
  background-color: lightgray !important;
}

.d-flex-b{
  display: flex;
  justify-content: space-between;
  width: 100%;
}