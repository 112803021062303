$gray: #7777;
$lightGray: #F7F7F7;
$darkGray: #777777;
$blue: #0D6EFD;
$invalRed: #DC3545;

.articleMargim {
    margin-top: 30px;
}

.articleGray {
    background-color: $lightGray;
    margin-top: 30px;
    padding: 30px;
}

.articleGrayLine {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

.articleGrayLine:nth-child(2) {
    margin-top: 30px;
}

.articleGrayLine > * {
    width: 25%;
}

.articleGrayTable {
    border: solid thin $gray;
    background-color: white;
    border-radius: 4px;
}

.invalidTable {
    border: solid thin $invalRed;
    background-color: white;
    border-radius: 4px;
}

.articleGrayTable,
.sectionGrayLineColumm {
    width: 25%;
}

.table {
    border-radius: 4px;
}

.table,
.formLabel,
.articleTitleTable {
    font-size: medium;
}

.articleTitleTable {
    padding: 10px 0 0 10px;
    font-weight: bold;
}

.displayFlex {
    display: flex;
}

.articleFormsLine {
    display: flex;
    justify-content: flex-start;
}

.buttonMarginRight {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0px;
}

.footer {
    display: flex;
    position: fixed;
    height: 50px;
    background-color: #EFEFEF;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: center !important;
    align-items: center;
    padding: 20px;
    box-shadow: 5px 5px 5px 5px #00000040;
}

.infoBlockDisplay{
    display: flex;
    justify-content: space-around;
}

.infoBlock {
    display: flex;
    justify-content: space-around;
}

.infoBlockColumm {
    display: flex;
    margin: 10px;
    flex-direction: column;
    width: 30vh;
}

.fontBlue {
    color: $blue;
}

.formSelect,
.formControl {
    width: 100%;
    margin-bottom: 20px;
}

.formControlDate,
.forControlLacre {
    width: 20vh;
}

.formControlCheck {
    width: 27vh;
}

.formLabel {
    font-weight: bold;
}

.articleMarginBottom {
    margin-bottom: 10vh;
}

.spanOpcional {
    font-weight: normal;
    font-size:13px;
    color: $darkGray;
    margin-left: 5px;
}

.formControlText {
    width: 100%;
    height: max;
}

.buttonMarginSide {
    width: 23%;
    margin-right: 0.5rem;
    height: 45px;
}

.showFeedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: $invalRed;
}

.gray {
    color: $gray
}

.showFeedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: $invalRed;
}

.hoverArr {
    cursor: pointer;
}