.bg-lighter{
  background-color: #EEE;
}

.cursor-pointer{
  cursor: pointer;
}

.testes-table{
  height: 400px;
  overflow: auto;
}

.btn-div{
  width: 50px;
}

.overflow-hd{
  overflow: auto;
}